<template>
  <div>
    <v-col cols="12">
      <v-card>
        <v-row class="ml-1 mr-1">
          <v-col cols="12" sm="2" md="2">
            <v-select
              label="Año del Grupo"
              v-model="selectedYear"
              :items="yearList"
              hide-details
              @change="fetchGroups"
              :loading="loadings.yearList"
              :disabled="loadings.yearList"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="5" md="5">
            <v-combobox
              v-if="selectedYear"
              label="Seleccionar Grupo"
              v-model="selectedGroup"
              :items="groupList"
              item-text="name"
              item-value="_id"
              hide-details
              :loading="loadings.groupList"
              :disabled="loadings.groupList"
              :filter="
                (item, queryText, itemText) => {
                  return (
                    itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
                    item.courseName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
                  )
                }
              "
              @change="onSelectedGroup"
            >
              <template #item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.courseName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" sm="5" md="5" v-if="selectedGroup">
            <v-combobox
              label="Seleccionar Materia"
              v-model="selectedSubject"
              :items="selectedGroup.subjects"
              item-text="name"
              item-value="_id"
              hide-details
              @change="onSelectedSubject"
            >
              <template #item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="subjectData.name">
      <v-card>
        <v-card-title> Materia {{ subjectData.name }} </v-card-title>
        <subject-panel
          :subject-data="subjectData"
          :group-data="selectedGroup"
          @reloadGroups="reloadGroups"
        ></subject-panel>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted, ref, inject } from 'vue'

import groupStoreModule from '../group/groupStoreModule'
import SubjectPanel from './group-panel/SubjectPanel.vue'

export default {
  components: {
    SubjectPanel,
  },
  setup() {
    const appGroup = 'app-group'

    // Register module
    if (!store.hasModule(appGroup)) store.registerModule(appGroup, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appGroup)) store.unregisterModule(appGroup)
    })

    const userData = store.getters['user/getUser']

    const groupList = ref([])
    const yearList = ref([])
    const selectedGroup = ref()
    const subjectList = ref([])
    const subjectData = ref({})
    const selectedSubject = ref()
    const selectedYear = ref()
    const date = inject('date')
    const loadings = ref({ yearList: false, groupList: false })

    const fetchGroups = async () => {
      groupList.value = []
      loadings.value.groupList = true
      await store
        .dispatch('app-group/fetchGroups', {
          year: selectedYear.value,
        })
        .then(response => {
          const { filteredData } = response.data
          groupList.value = filteredData
          loadings.value.groupList = false
        })
        .catch(error => {
          if (error.response.status === 404) {
            groupList.value = []
            loadings.value.groupList = false
          }
        })
    }

    const onSelectedGroup = () => {
      selectedSubject.value = undefined
      subjectData.value = {}
    }

    const onSelectedSubject = () => {
      subjectData.value = selectedSubject.value
    }

    const reloadGroups = async () => {
      await fetchGroups()
      selectedGroup.value = groupList.value.filter(group => group._id == selectedGroup.value._id).at(0)
      subjectData.value = selectedGroup.value.subjects.filter(subject => subject._id == selectedSubject.value._id).at(0)
    }

    const fetchYears = async () => {
      loadings.value.yearList = true
      const response = await store.dispatch('app-group/fetchYears')

      yearList.value = response.data.years
      if (!selectedYear.value) selectedYear.value = Number(date().format('YYYY'))

      fetchGroups()
      loadings.value.yearList = false
    }

    fetchYears()

    return {
      fetchGroups,
      loadings,
      yearList,
      selectedYear,
      groupList,
      selectedGroup,
      onSelectedGroup,
      subjectList,
      onSelectedSubject,
      selectedSubject,
      subjectData,
      reloadGroups,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/group.scss';
</style>
