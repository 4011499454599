<template>
    <v-menu
        ref="menuref"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            :label="label"
            :prepend-icon="icons.mdiClockTimeFourOutline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-model="time"
          v-if="menu"
          color="primary"
          format="24hr"
          full-width
          @click:minute="sendTime"
          :rules="rules"
        ></v-time-picker>
      </v-menu>
</template>
<script>
import { computed, ref, toRefs, watch } from 'vue'
import { mdiClockTimeFourOutline } from '@mdi/js'
import { required } from '@core/utils/validation'

export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const time = ref(props.value)
    const menu = ref(false)
    const menuref = ref(null)

    const sendTime = item => {
      menuref.value.save(time.value)
      emit('input', time)
      menu.value = false
    }

    watch(
      () => props.value,
      value => {
        time.value = value
      },
    )

    return {
      time,
      menu,
      sendTime,
      menuref,
      validators: { required },

      // icons
      icons: {
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>